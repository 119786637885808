<template>
  <v-menu
    v-model="menuVisible"
    :close-on-content-click="false"
    nudge-top="40px"
    offset-y
  >
    <template v-slot:activator="{ attrs, on }">
      <div
        :class="[
          'tp-input-search',
          'white',
          'rounded-lg',
          { 'cursor-wait': loadingCursor }
        ]"
      >
        <v-text-field
          v-model="search"
          :disabled="disabled"
          :label="getLabel()"
          :outlined="outlined"
          class="rounded-lg text-body-1"
          clearable
          dense
          flat
          full-width
          hide-details
          prepend-inner-icon="mdi-magnify"
          solo
          @click:clear="clearSearchKey()"
          @keydown.enter="updateSearchKey()"
        >
          <template v-if="options.length === 1 && description" slot="append">
            <v-tooltip
              bottom
              color="white"
              content-class="elevation-4 px-2"
              nudge-top="5px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon small v-bind="attrs" v-on="on">
                  <v-icon color="grey" size="20px"
                    >mdi-information-outline
                  </v-icon>
                </v-btn>
              </template>
              <div class="black--text text-body-2" style="width: 180px">
                <span class="font-weight-medium">Cho phép tìm kiếm: </span
                >{{ description }}
              </div>
            </v-tooltip>
          </template>
          <template v-if="options.length > 1" slot="append">
            <v-btn icon small v-bind="attrs" v-on="on">
              <v-icon color="grey">mdi-menu-swap</v-icon>
            </v-btn>
          </template>
        </v-text-field>
      </div>
    </template>

    <v-list v-if="options.length > 1" class="px-1 pt-3 pb-7">
      <v-list-item v-for="(item, index) in options" :key="index">
        <v-text-field
          :label="item.label"
          :value="activeOption === item.type ? search : ''"
          class="text-body-1"
          dense
          hide-details
          @focus="changeActiveOption(item)"
          @input="search = $event"
          @keydown.enter="updateOptionInput()"
        ></v-text-field>
      </v-list-item>
      <v-list-item>
        <v-btn
          class="btn-text--normal mt-4"
          color="primary"
          depressed
          @click="updateOptionInput()"
        >
          <v-icon class="mr-1" left>mdi-magnify</v-icon>
          Tìm kiếm
        </v-btn>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    defaultOption: {
      type: [Number, String],
      default: 0
    },
    description: {
      type: String
    },
    disabled: {
      type: Boolean,
      default: false
    },
    options: {
      type: Array,
      default: function() {
        return [];
      }
    },
    loadingCursor: {
      type: Boolean,
      default: false
    },
    outlined: {
      type: Boolean,
      default: false
    },
    value: {
      type: String
    }
  },
  data() {
    return {
      activeOption: null,
      menuVisible: false,
      search: null
    };
  },
  watch: {
    defaultOption: {
      handler(val) {
        if (typeof val === "number") {
          this.activeOption = this.options[val].type;
        } else if (val && this.activeOption !== val) {
          this.activeOption = val;
        }
      },
      immediate: true
    },
    search(val) {
      if (val !== null && val.length === 0) {
        this.$emit("search", {
          option: this.activeOption,
          searchKey: null
        });
      }
    },
    value(val) {
      if (val && val.trim() !== "") {
        this.search = val;
      } else {
        this.search = "";
      }
    }
  },
  created() {
    if (this.value) {
      this.search = this.value;
    }
  },
  methods: {
    getLabel() {
      const activatedOption = this.options.find(
        option => option.type === this.activeOption
      );

      return activatedOption ? activatedOption.label : "";
    },
    changeActiveOption(item) {
      this.search = null;
      this.activeOption = item.type;
    },
    clearSearchKey() {
      this.search = null;
      this.$emit("search", {
        option: null,
        searchKey: null
      });
    },
    updateSearchKey() {
      console.log("updateSearchKey");

      this.$emit("search", {
        option: this.activeOption,
        searchKey: this.search
      });
    },
    updateOptionInput() {
      this.menuVisible = false;
      this.$emit("search", {
        option: this.activeOption,
        searchKey: this.search
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.cursor-wait {
  cursor: wait;
}

.tp-input-search {
  max-width: 400px;
  width: 100%;
}
</style>
